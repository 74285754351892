.img-fluid {
  max-width: 100%;
  height: 360px;
  object-fit: cover;
  max-height: 300px;
}

h2,
h4,
h3 {
  font-family: "Vollkorn", serif !important;
}

h2.ff-heading {
  font-family: "Vollkorn", serif !important;
}
.events-img {
  height: auto;
}
.events-img img {
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .events-img {
    width: 100%;
    height: 300px;
  }
}
/* =========================
sidebar css
========================= */

.widget {
  /* background: #F5F7FA; */
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 3px grey;
}

.widget .widget-title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  position: relative;
  font-size: 25px;
  color: var(---SecondColor);
}

.widget .widget-title::before {
  position: absolute;
  content: "";
  width: 15px;
  border-bottom: 3px solid var(---PrimeColor);
  bottom: 0;
  left: 0;
}

.widget .widget-title::after {
  position: absolute;
  content: "";
  width: 30px;
  border-bottom: 3px solid var(---PrimeColor);
  bottom: 0;
  left: 22px;
}

.widget .search-form .form-control {
  padding: 12px 15px 12px 15px;
  border-radius: 0px;
  box-shadow: none;
}

.widget .search-form {
  position: relative;
}

.widget .search-form .form-control:focus {
  border-color: var(---PrimeColor);
}

.widget .search-form button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  padding: 8px 18px 6px 18px;
  background: transparent;
  border: none;
  color: var(---PrimeColor);
}

.widget .category-list {
  display: block;
  padding: 10px 0;
  font-weight: 500;
  border-bottom: 1px solid #ced4da;
  transition: all 0.5s ease-in-out;
}

.widget .category-list:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.widget .category-list .nav-link{
  transition: 0.3s ease-in-out;
  text-align: left;
}

.widget .category-list a.active,
.widget .category-list .nav-link.active,
.widget .category-list .nav-link:hover {
  padding-left: 10px;
  color: #a7856a;
  transition: 0.3s ease-in-out;
  text-align: left;
}
.widget .category-list a{
  color: #111;
}
.widget .category-list i {
  margin-right: 5px;
  color: #111;
  transition: 0.3s ease-in-out;
}

.widget .category-list span {
  float: right;
}

.widget .recent-post-single {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}

.widget .recent-post-img {
  margin-right: 20px;
}

.widget .recent-post-img img {
  width: 120px;
}

.widget .recent-post-bio h6 {
  font-size: 18px;
}

.widget .recent-post-bio span {
  font-size: 14px;
  color: var(---PrimeColor);
  font-weight: 500;
}

.widget .recent-post-bio span i {
  margin-right: 5px;
}

.widget .recent-post-bio h6 a:hover {
  color: var(---PrimeColor);
}

.widget .social-share-link a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  border: 2px solid var(---PrimeColor);
  color: var(---PrimeColor);
  text-align: center;
  margin-right: 5px;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}

.widget .social-share-link a:hover {
  background: var(---PrimeColor);
  color: #fff;
}

.widget .tag-list a {
  background: #fff;
  color: var(---SecondColor);
  padding: 5px 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.5s ease-in-out;
}

.widget .tag-list a:hover {
  background-color: var(---PrimeColor);
  color: #fff;
}

.menu_style1 .single_menu_list img {
  position: static;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 45px;
}
.single_menu_list h4 {
  font-size: 18px;
  border-bottom: 1px dashed #333;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.single_menu_list h4 span {
  float: right;
  font-weight: bold;
  color: #ff5e18;
  font-style: italic;
}
/* p {
    font-weight: 300;
    font-size: 14px;
  } */
.menu_style1 .single_menu_list {
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 0 2px rgb(195, 195, 195);
}
.single_menu_list:hover img {
  border-radius: 0;
  transition: 0.4s;
}
.service-sidebar {
  position: sticky;
  top: 0;
}
.foodicon {
  width: 60px;
  height: 60px;

  box-shadow: 0 0 3px rgb(169, 167, 167);
  margin-right: 20px;
}
.foodItems {
  padding: 20px;
}
.checkList li {
  list-style: decimal;
  margin-left: 20px;
}
.orderBtn {
  background: #efc839 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  /* padding: 15px 38px; */
  text-transform: uppercase;
  border-radius: 50px;
  box-shadow: 0px 5px 20px rgba(52, 47, 47, -1);
}
