/*--------------------------
   23. Contact us pages css here
--------------------------*/
.contact-icon > a {
  color: #666666;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 50px;
}
.contact-icon > a:before {
  background: rgba(0, 0, 0, 0) url("../../assets/services/border.webp") no-repeat scroll center center / cover ;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999999;
}
.single-contact-information p {
  line-height: 20px;
  margin-bottom: 0;
  text-align: center !important;
}
.single-contact-information .contact-icon {
  margin-bottom: 12px;
}
.contact-information {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
.contact-bottom-section .col-lg-6.map-div {
    padding-right: 0;
}
.contact-bottom-section .col-lg-6.contact-form-div .contact-form {
    float: right;
    padding-left: 30px;
    /* width: 585px; */
    position: relative;
}
.contact-form-title h2 {
  color: #fff !important;
  font-family: "Great Vibes",cursive;
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: capitalize;
}
.contact-form-box input {
    border: 1px solid #dddddd;
    color: #aaaaaa;
    margin-bottom: 18px;
    padding-left: 20px;
}
.contact-form-box textarea {
    border: 1px solid #ddd;
    height: 100px;
    padding-left: 20px;
    resize: none;
}
.contact-form-box button {
    background: #e54c2a none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 40px;
    padding: 12px 0;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 100%;
}
.contact-form-box button:hover{background: #555;}
.contact-bottom-section {
    position: relative;
}
.contact-bottom-section .bg-img {
  background: rgba(0, 0, 0, 0) url("../../images/dosabg.png") no-repeat scroll left center / cover ;
  bottom: 0;
  background-attachment: fixed;
  height: 100%;
  position: absolute;
  width: 100%;
}
.form-messege.success {
  color: green;
  text-transform: uppercase;
  font-style: italic;
}
.form-messege.error {
  color: red;
  text-transform: capitalize;
  font-style: italic;
}