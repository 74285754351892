.img-fluid{
    max-width: 100%;
    height: 360PX !important;
    object-fit: cover;
}

h2{
    font-family: 'Vollkorn', serif;
}

h2.ff-heading{
    font-family: 'Vollkorn', serif !important;
}