.img-fluid{
    max-width: 100%;
    height: 360PX !important;
    object-fit: cover;
}

h2{
    font-family: 'Vollkorn', serif;
}

h2.ff-heading{
    font-family: 'Vollkorn', serif !important;
}

.about-section .info-block .inner-box{
    position: relative;
    border: 1px solid var(--main-color);
    padding-top: var(--padding-top-90);
    padding-bottom: var(--padding-bottom-50);
    margin-left: var(--margin-left-15);
    margin-right: var(--margin-right-15);
  }
  
  .about-section .info-block .inner-box:before{
    content: '';
    position: absolute;
    left: -14px;
    top: 12px;
    right: -14px;
    bottom: 12px;
    border: 1px solid var(--main-color);
  }
  
  .about-section .info-block .info{
    max-width: 240px;
    margin: 0 auto;
  }
  
  .about-section .info-block .info li{
    position: relative;
    color: var(--color-one);
    line-height: 1.5em;
    margin-bottom: var(--margin-bottom-25);
  }
  
  .about-section .info-block .info li:last-child{
    margin-bottom: 0;
  }
  
  .about-section .info-block .info li strong{
    position: relative;
    display: block;
    color: var(--text-color);
    margin-bottom: var(--margin-bottom-5);
  }
  
  .about-section .info-block .info li .address{
    position: relative;
    display: block;
    margin-bottom: var(--margin-bottom-10);
  }
  
  .about-section .info-block .info li a{
    color: var(--color-one);
  }
  
  .about-section .info-block .info li a:hover{
    color: var(--main-color);
  }
  
  .about-section .info-block .info li.separator{
    position: relative;
    margin-bottom: var(--margin-bottom-30);
  }
  
  .about-section .info-block .info li.separator span{
    position: relative;
    display: block;
    height: 8px;
    width: 8px;
    border: 1px solid var(--main-color);
    margin: 0 auto;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .about-section .fact-counter{
    background-color: #000;
    position: relative;
    padding-top: var(--padding-top-60);
  }
  .pt-100{padding-top: 80px}  
.contact-bottom-section .col-md-6.contact-form-div .contact-form {
  width: 485px;
}    
  .about-section .fact-counter .fact-block{
    position: relative;
    text-align: center;
    margin-bottom: var(--margin-bottom-50);
  }
  
  .about-section .fact-counter .fact-count{
    position: relative;
    line-height: 60px;
    font-size: var(--font-60);
    font-family:var(--font-family-Forum);
  }
  
  .about-section .fact-counter .count-box{
    position: relative;
  }
  
  .about-section .fact-counter .count-box i{
    font-style: normal;
  }
  
  .about-section .fact-counter .fact-title{
    position: relative;
    display: block;
    padding-top: var(--padding-top-5);
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    font-size: var(--font-12);
    letter-spacing: 0.40em;
  }