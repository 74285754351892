.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(68, 67, 67, 0.3);
    top:40% !important;
    right: 10px !important;
    padding: 20px;
    color: #43c3ea !important;
    fill: var(---primeColor) !important;
    stroke: var(---primeColor) !important;
    border-radius: 5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 1.4rem !important;
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}

.menu_content h4{
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .mySwiper {
    height: 70vh !important;
  }

  #firstSlide {
    height: 70vh !important;
  }
}


@media (max-width: 500px) {
  .mySwiper {
    height: 50vh !important;
  }

  #firstSlide {
    height: 50vh !important;
  }
}


