
/* ===== Services Banner ===== */

.ServicesBanner {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  background:linear-gradient(
    120deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  ),url("../../assets/aboutBanner.jpg");
  z-index: 1;
 
}

.ServicesBanner .ServicesBanner-title {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .ServicesBanner {
    height: 40vh;
  }
  .ServicesBanner .ServicesBanner-title {
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
}


/* ========= Service Pages ===== */

.service-single-wrapper .widget-title {
    font-weight: 700;
  }

  .service-single-list li {
    color:#808080;
    font-size: 15px;
    font-family: var(---ParaFont);
  }
  
  .service-single-list i {
    color:var(---PrimeColor);
    margin-right: 10px;
  }
  .service-single-list span {
    color:#000;
    text-transform: capitalize;
    font-weight: 700;
    margin-right: 10px;
  }
  
  .service-download a {
    border: 2px solid var(---PrimeColor);
    padding: 10px 20px;
    color: var(---SecondColor);
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    transition: all .5s ease-in-out;
  }
  
  .service-download a i {
    margin-right: 10px;
  }
  
  .service-download a:hover {
    background-color: var(---PrimeColor);
    color: #fff;
  }

  .service-detail{
    box-shadow: 0 0 3px grey;
    padding: 20px;
  }
  
  .service-details h3 {
    color: var(---SecondColor);
    font-weight: 700;
  }
  .service-details-img{
    width: 100%;
    height:520px;
  }
  .service-details-img img{
    width: 100%;
    height:100%;
    box-shadow: 0 0 5px grey;
  }

  @media (max-width: 991px) {
    .service-details-img{
      width: 100%;
      height:auto;
    }
  }
  .selected-all-checkBoxBtn{
    float: inline-end;
    background-color: #e54c2a !important;
    border: #e54c2a !important;
    color: white !important;
  }
.service-selected-check-box{
     padding: unset !important;
}
