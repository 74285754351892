


.ContactUs-form-Section-4 {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.contact-form-image {
    height: 60vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.form-control {
    background-color: rgb(228, 228, 228);
    height: 50px;
}

.Email {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Phone-no {
    margin-top: 13px;
    margin-bottom: 20px;
}

.message textarea {
    height: 100px;
}

.form-submit-button {
    border: none;
    margin-top: 20px;
    width: 200px;
    height: 35px;
    border-radius: 5px;
}

@media(max-width:767px) {
    .Name-input {
        margin-top: 20px;
    }

   
}


.form-submit-button{
    border: none !important;
    border-radius: 5px !important;
    height: 35px !important;
    margin-top: 20px !important;
    width: 200px !important;
}