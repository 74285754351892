.Menu-Section-1 {
    /* border: 1px solid red; */
    height: 60vh;
  }
  
  .Menu-Section-con {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    /* background-image: url("../Images/page-title-1.jpg"); */
    background-size: 100% 100%;
  }
  
  .Menu-Section-Text {
    margin: 0px 0px 0px 30px;
  
  }
  
  .Menu-Section-NavPage {
    display: flex;
    align-items: center;
  }
  
  .Menu-Section-Text h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---Font4);
    font-weight: 600;
    font-size: 45px;
    letter-spacing: 1px;
    color: white;
  }
  
  .Menu-Section-NavPage span {
    font-family: var(---Font1);
    color: white;
  }
  
  .Menu-Section-NavPage span svg {
    font-size: 25px;
    margin: 0px 10px 0px 0px;
  }
  
  .Menu-Section-NavPage h3 {}
  
  .Menu-Section-NavPage h3 svg {
    color: white;
  }
  
  .Menu-Section-NavPage h2 {
    font-size: 16px;
    margin: 0px;
    font-family: var(---Font1);
    font-weight: normal;
    color: #b48c80;
  }
  
  
  /* ------------ book --------------- */
  
  .Menu-Section-3 {
    /* border: 1px solid red; */
    background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6)), url("../../assets/Upwas-Blog.webp");
    background-size: cover;;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    padding: 10vh 0px;
  }
  
  .Menu-Section-3-con {
    /* border: 1px solid blue; */
    padding: 20px;
  }
  
  .book {
    /* border: 1px solid blue; */
    transition: opacity 0.4s 0.2s;
    display: flex;
    justify-content: center;
  }
  
  /* p{
      margin-top: 8vw;
      text-align: center;
      font-size: 5vw;
      color: #000000;
    } */
  .page {
    width: 30vw;
    height: 44vw;
    background-color: #111111;
    float: left;
    margin-bottom: 0.5em;
    background: left top no-repeat;
    background-size: cover;
  }
  
  .page img {
    height: 100%;
    width: 100%;
  }
  
  .page:nth-child(even) {
    clear: both;
  }
  
  .book {
    perspective: 250vw;
  }
  
  .book .pages {
    width: 60vw;
    height: 44vw;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 4px;
    /*box-shadow: 0 0 0 1px #e3dfd8;*/
  }
  
  .book .page {
    float: none;
    clear: none;
    margin: 0;
    position: absolute;
    top: 0;
    width: 30vw;
    height: 44vw;
    transform-origin: 0 0;
    transition: transform 1.4s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    cursor: pointer;
    user-select: none;
    background-color: #f0f0f0;
  }
  
  .book .page:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.7s;
    z-index: 2;
  }
  
  .book .page:nth-child(odd) {
    pointer-events: all;
    transform: rotateY(0deg);
    right: 0;
    border-radius: 0 4px 4px 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
  }
  
  .book .page:nth-child(odd):hover {
    transform: rotateY(-15deg);
  }
  
  .book .page:nth-child(odd):hover:before {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .book .page:nth-child(odd):before {
    background: rgba(0, 0, 0, 0);
  }
  
  .book .page:nth-child(even) {
    pointer-events: none;
    transform: rotateY(180deg);
    transform-origin: 100% 0;
    left: 0;
    border-radius: 4px 0 0 4px;
    border-color: black;
    background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
  }
  
  .book .page:nth-child(even):before {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .book .page.grabbing {
    transition: none;
  }
  
  .book .page.flipped:nth-child(odd) {
    pointer-events: none;
    transform: rotateY(-180deg);
  }
  
  .book .page.flipped:nth-child(odd):before {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .book .page.flipped:nth-child(even) {
    pointer-events: all;
    transform: rotateY(0deg);
  }
  
  .book .page.flipped:nth-child(even):hover {
    transform: rotateY(15deg);
  }
  
  .book .page.flipped:nth-child(even):hover:before {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .book .page.flipped:nth-child(even):before {
    background: rgba(0, 0, 0, 0);
  }
  
  /* *,
    * :before,
    *:after {
      box-sizing: border-box;
    } */
  /* html,
    body {
      font-family: 'Lovers Quarrel', cursive;
      background: #333;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    } */
  /* html {
      height: 100%;
    }
    body {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2em 0;
      line-height: 1.5em;
    } */
  .page:nth-child(odd) {
    background-position: right top;
  }
  
  @media(max-width:430px) {
    .Menu-Section-1 {
      height: 40vh;
    }
  
    .Menu-Section-3 {
      padding: 3vh 0px;
    }
  
    .book .pages {
      width: 80vw;
      height: 65vw;
    }
  
    .book .page {
      float: none;
      clear: none;
      margin: 0;
      position: absolute;
      top: 0;
      width: 40vw;
      height: 65vw;
    }
  
    .Menu-Section-3-con {
      padding: 0px;
    }
  }