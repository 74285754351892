/*-----------------------------------
    2.2 SLIDER AREA
-------------------------------------*/

.header-top-area {
  position: absolute;
  width: 100%;
  z-index: 999;
  text-transform: uppercase;
}

.slider-area {
  font-family: "Raleway", sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
  font-weight: 900;
  color: #fff;
}

.slider-area h1 {
  font-size: 65px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  color:#fff !important;
}

.slider-area h1 span {
  font-weight: 700;
}

.slider-area h2 {
  /* font-family: "Dancing Script", cursive; */
  font-size: 82px;
  letter-spacing: 2px;
  text-transform: capitalize;
  color:#fff !important;
}

.slider-area h3 {
  font-family: lato;
  font-weight: 700;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.slider-area h3 a,
a.home-reservation-button {
  border: 1px solid;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  margin-top: 15px;
  padding: 10px 30px;
}

.slider-area h3 a:hover,
a.home-reservation-button:hover {
  color: #fff;
  background: #ca3d26 none repeat scroll 0 0;
  border-color: #ca3d26;
}
@media(max-width:554px) and  (min-width:200px)
{
  .slider-area h2 {
    /* font-family: "Dancing Script", cursive; */
    font-size: 46px;
    letter-spacing: 2px;
    text-transform: capitalize;
    color:#fff !important;
  }
}
.pogoSlider {
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  padding-bottom: 0;
}

.pogoSlider-slide {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  -webkit-perspective: 1300px;
  perspective: 1300px;
  position: absolute;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 5%;
}
#firstSlide{
  background:url("../../assets/services/slide_3.jpg");
  height:90vh;
  animation: shrink 5s infinite alternate;
}
.pogoSlider-slide::after {
  background: #000  repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

/*-------------------------
    15.1 Breadcrubs css here
-------------------------------*/
.breadcrubs {
  background: rgba(0, 0, 0, 0) url("../../assets/breadcrubs.webp") no-repeat scroll center center / cover ;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 100px 0;
  position: relative;
  z-index: 9;
}
h3.breadcrubs-title {
    color: #535353;
    font-family: "Great Vibes",cursive;
    font-size: 36px;
    text-transform: capitalize;
}
.breadcurbs-inner ul li {
    color: #e54c2a;
    display: inline-block;
    text-transform: uppercase;
}
.breadcurbs-inner ul li a {
    color: #535353;
}
.breadcurbs-inner ul li span {
    margin: 0 10px;
}
.breadcurbs-inner li a:hover {
    color: #e54c2a;
}

@keyframes zoom-in-zoom-out {
  /* At the beginning of the animation */
  0% {
    /* Scale the element to its original size */
    transform: scale(1, 1);
  }
  /* At the middle of the animation */
  50% {
    /* Scale the element to 1.5 times its original size */
    transform: scale(1.5, 1.5);
  }
  /* At the end of the animation */
  100% {
    /* Scale the element back to its original size */
    transform: scale(1, 1);
  }
}