  
/* ======================================= Footer Section ============================== */
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Satisfy&display=swap');
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
/*------------------------
    10. Testmonial Css here
----------------------------*/
.testimonial {
  background: rgba(0, 0, 0, 0) url("../../images/bg-1.webp") no-repeat scroll right top ;
}
.single-test-text {
  padding: 50px 70px;
}
.testimonial-text-slider {
  background: rgba(0, 0, 0, 0) url("../../images/bg-2.webp") no-repeat scroll center center / cover ;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  height: inherit !important;
  position: relative;
}
.testimonial-text-slider.slick-slider {
  margin-top: 40px;
}
.testimonial-text-slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.sin-testiImage img {
  border: 1px solid #fff;
  cursor: pointer;
  display: inline-block !important;
  height: 150px;
  margin-bottom: 30px;
  margin-top: 36px;
  outline: 0 none;
  -webkit-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  width: 150px;
}
.sin-testiImage.slick-current img {
  border: 2px solid #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  height: 180px;
  margin-top: 20px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 180px;
}
.testimonial .slick-slider {
  height: 223px;
  z-index: 99;
}
.testimonial .testimonial-image-slider.slick-slider {
  margin-top: -20px;
}
.sin-testiImage {
    position: relative;
}
.sin-testiImage:before {
    background: none repeat scroll 0 0 rgba(229, 76, 42, 0.5);
    content: "";
    height: 150px;
    left: 18px;
    position: absolute;
    top: 36px;
    width: 150px;
    pointer-events: none;
}
.sin-testiImage.slick-current:before {
  display: none;
}
.testimonial-text-slider:before {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.79);
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
@media(max-width:554px) and (min-width:200px)
{
  .single-test-text {
    padding:20px;
  }
  .testimonial {
    background: rgba(0, 0, 0, 0) url("../../images/bg-1.webp") no-repeat scroll right bottom 200% ;
  }
}
p.text-qoute i {
    color: #e54c2a;
    font-size: 30px;
}
p.test-text {
    font-style: italic;
}
.test-title h4 {
  color: #e54c2a;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}
.test-title > p {
    margin: 0;
}
.testimonial-sliders .slick-dots li a {
    color: #535353;
    font-size: 16px;
    font-weight: 600;
}
.testimonial-sliders .slick-dots li.slick-active a {
    color: #e54c2a;
    font-size: 24px;
}
.testimonial-sliders .slick-dots {
  bottom: 58px;
}
.sin-testiImage.slick-slide.slick-current.slick-active.slick-center:focus {
    outline: medium none !important;
}
.sin-testiImage.slick-slide.slick-active:focus {
    outline: medium none !important;
}
ul {
    margin: 0px;
    padding: 0px;
  }
  
  .footer-section {
    background:black ;
    position: relative;
  }
  
  .footer-cta {
    border-bottom: 1px solid #373636;
  }
  
  .single-cta i {
    color:white;
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }
  
  .cta-text {
    padding-left: 48px;
    display: inherit;
  }
  
  .cta-text h4 {
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .cta-text span {
    color: white;
    font-size: 15px;
  }
  
  .footer-content {
    position: relative;
    z-index: 2;
  }
  
  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  
  .footer-logo {
    margin-bottom: 30px;
  }
  
  .footer-logo img {
    max-width: 130px;
  }
  
  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: white;
    line-height: 28px;
  }
  
  .footer-social-icon span {
    color:white;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    
    margin-bottom: 20px;
  }
  
  .footer-social-icon a {
    color: var(---SecondColor);
    font-size: 16px;
    margin-right: 15px;
  }
  
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    color: #fff;
  }
  
  .facebook-bg {
    background: #3b5998;
  }
  
  .twitter-bg {
    background: #55acee;
  }
  
  .google-bg {
    background: #dd4b39;
  }
  .watsapp-bg {
    background: #47c757;
  }
  
  .footer-widget-heading h3 {
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: var(---SecondColor);
  }
  
  .footer-widget ul li {
 
    
    width: 50%;
    margin-bottom: 12px;
  }
  
  .footer-widget ul li a:hover {
    color: var(---SecondColor);
  }
  
  .footer-widget ul li a {
    color: white;
    text-transform: capitalize;
  }
  
  .subscribe-form {
    position: relative;
    /* overflow: hidden; */
  }
  
  .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #eee;
    border: 1px solid #eee;
    color: #111;
  }
  
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: var(---SecondColor);
    padding: 13px 20px;
    border: 2px solid var(---SecondColor);
    bottom: 10px;
  }
  
  .subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
  }
  
  .copyright-area {
    background: #202020;
    padding: 25px 0;
  }
  
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }
  
  .copyright-text p a {
    color: #fff;
  }
  
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  
  .footer-menu li:hover a {
    color: var(---primeColor);
  }
  
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  
 .bottomNavbar .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #808080 !important;
 }

 .bottomNavbar .MuiTabs-indicator{
  display: none;
 }
 
 .bottomNavbar .MuiBox-root{
  margin: auto;
 }

 .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset;
 }
 .carttotal{
  display: flex;
    position: relative;
    top: -43px;
    left: 11px;
    color: rgb(233, 140, 20);
}
.textwhite{
  color: white;
}
.liststyle{
  color: #fff;
}

/*
 *  STYLE 4
 */
 .scrollbar
 {
   margin-left: 30px;
   float: left;
   /* height: 300px; */
   width: 65px;
   background: #F5F5F5;
   /* overflow-y: scroll; */
   /* margin-bottom: 25px; */
 }
 #style-4::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   background-color: #F5F5F5;
 }
 
 #style-4::-webkit-scrollbar
 {
   width: 10px;
   background-color: #F5F5F5;
 }
 
 #style-4::-webkit-scrollbar-thumb
 {
   background-color: #000000;
   border: 2px solid #555555;
 }
 /* blockquote{
  height: 125px;
    overflow: auto;
 } */
 .testimonial-content{
  box-shadow: 2px 2px 2px 1px rgba(0,0,0,0.2);
 }