.footer-section {
  background:rgb(37, 37, 37);
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color:#c9c6c6;
  font-size: 30px;
  font-weight: 500;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 50px;
  /* display: inline-block; */
}
.cta-text h4 {
  color: #019edf !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #fff;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
/* .footer-logo {
    margin-bottom: 30px;
  } */
.footer-logo img {
  max-width: 155px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
}
.footer-social-icon span {
  color: #6d92dd;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: var(--primary-color);
}
.footer-widget-heading h3 {
  color: #019edf !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #019edf;
}
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #866b2a;
}
.footer-widget ul li a {
  color: #fff;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #866b2a;
  padding: 13px 20px;
  border: 1px solid #866b2a;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #000;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #866b2a;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #866b2a;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.floattingButton .float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: #f26522;
  color: #434343;
  border-radius: 50px;
  text-align: center;
  z-index: 1000;
  animation: bot-to-top 2s ease-out;
  overflow-x: hidden;
}

.floattingButton ul {
  position: fixed;
  right: 40px;
  padding-bottom: 20px;
  bottom: 80px;
  z-index: 100;
}

.floattingButton ul li {
  list-style: none;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #fff;
}

.floattingButton ul li:first-child {
  background-color:rgb(197, 60, 151);
  border-radius: 50px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: block;
}

.floattingButton ul li:last-child {
  background-color: blue;
  border-radius: 50px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: block;
}

.floattingButton ul:hover {
  visibility: visible!important;
  opacity: 1!important;
}

.floattingButton a#menu-share+ul {
  visibility: hidden;
}

.floattingButton a#menu-share:hover+ul {
  visibility: visible;
  animation: scale-in 0.5s;
}

.floattingButton .my-float {
  font-size: 22px;
  margin-top: 8px;
}

@media(max-width: 768px) {
  /* .floattingButton .float {
position: fixed;
width: 50px;
height: 50px;
bottom: 36px;
right: 18px;
}
.floattingButton .my-float {
font-size: 20px;
margin-top: 16px;
} */
  .floatingText p {
      display: none;
  }
}

.floattingButton a#menu-share i {
  animation: rotate-in 0.5s;
}

.floattingButton a#menu-share:hover>i {
  animation: rotate-out 0.5s;
}
.copyright-text {
  display: flex;
  justify-content: space-between;
}
/**********************************
 	Footer CSS Start
 **********************************/

 .footer-section {
  background: url(../../assets/services/footer-bg.jpg);
  background-position: center;
  background-size: cover;
}

.cat-footer-social ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.cat-footer-social ul li {
  list-style: none;
  margin: 0 0 10px;
}

.cat-footer-social ul li+li {
  margin-left: 20px;
}

.cat-footer-social ul a {
  width: 50px;
  height: 50px;
  border:1px solid #efc839;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  z-index: 1;
  position: relative;
}

.cat-footer-social ul a:hover::after {
  box-shadow: inset 0 0 0 2px var(--cat-white);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.cat-footer-social ul a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  box-shadow: inset 0 0 0 35px var(--cat-primary-color);
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}

.cat-footer-social ul a svg {
  fill: #fff;
  height: 16px;
}

.cat-footer-social ul a:hover>svg {
  transform: rotate( 360deg);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}

.cat-copyright-area {
  border-top: 1px solid rgb(255 255 255 / 15%);
  padding: 20px 0 0;
  font-size: 16px;
  margin: 20px auto 0;
  max-width: 800px;
}


.cat-footer-logo p {
  font-size: 22px;
  max-width: 530px;
  margin: 30px auto 20px;
  color:#fff;
  text-align: center;
}
