.breadcrumb-section {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.jarallax{
    background-repeat: no-repeat;
    background-size: cover;
}
#about
{
    padding:10px 20px;
}